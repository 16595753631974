import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './../auth.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm!: FormGroup;

    public emailMaxLength = 100;
    public passwordMinLength = 8;
    public passwordMaxLength = 40;

    constructor(private formBuilder: FormBuilder) {
    }

    public ngOnInit(): void {
        this.buildLoginForm();
    }

    public get emailAddress(): AbstractControl {
        return this.loginForm.controls['email'];
    }

    public get password(): AbstractControl {
        return this.loginForm.controls['password'];
    }

    public login(): void {
        console.log(this.loginForm.getRawValue());
    }

    private buildLoginForm(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', {validators: [Validators.required, Validators.email, Validators.maxLength(this.emailMaxLength)]}],
            password: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]
            }]
        });
    }
}
