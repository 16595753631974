import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-company-link',
    templateUrl: './company-link.component.html',
    styleUrl: './company-link.component.scss'
})
export class CompanyLinkComponent {
    @Input() pageHome = false;
    public currentDate: Date = new Date();
}
