import {Component} from '@angular/core';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrl: './password.component.scss'
})
export class PasswordComponent {

}
