import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MaterialModule} from "../shared/material/material.module";
import {MatCardImage} from "@angular/material/card";
import {RouterModule, Routes} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";

// auth pages
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {PasswordComponent} from './password/password.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {RegisterComponent} from './register/register.component';

// auth components
import {AuthTopComponent} from './components/auth-top/auth-top.component';
import {SharedModule} from "../shared/shared.module";


const routes: Routes = [

    // auth pages
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, title: 'Login'},
    {path: 'password', component: PasswordComponent, title: 'Password'},
    {path: 'password-reset', component: PasswordResetComponent, title: 'Password Reset'},
    {path: 'register', component: RegisterComponent, title: 'Register'}

];

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        PasswordComponent,
        PasswordResetComponent,
        RegisterComponent,
        AuthTopComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MaterialModule,
        NgOptimizedImage,
        MatCardImage,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class AuthModule {
}
