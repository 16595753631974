import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-auth-top',
    templateUrl: './auth-top.component.html',
    styleUrl: './auth-top.component.scss'
})
export class AuthTopComponent {
    @Input() public closeLink = '/';
    @Input() public title = '';
    @Input() public subTitle = '';
    @Input() public subTitleSecondLine = '';
}
